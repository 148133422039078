$fl-app-link: #61dafb;
$fl-text-black: #000000;
$fl-text-dark: #130f1a;
$fl-text-white: #ffffff;
$fl-text-red: red;
$fl-text-orange: #ee7724;
$fl-text-gray: gray;
$fl-text-brown: brown;
$fl-text-green: green;

$fl-background-white: #ffffff;
$fl-background-brown: #f47a511f;
$fl-background-orange: #f47951;
$fl-background-black: #000000;
$fl-background-grey: #b5b5b5;

$fl-border-orange: #f47951;

$fl-body-background: rgb(247, 247, 247);
$fl-dropdown-border: #e7e0e0e3;
$fl-back-button-hover: #333;

/* Login button styles */
$fl-login-button-border: #ededed;
$fl-login-button-hover: rgba(0, 0, 0, 0.07);
$fl-login-description: #111;

/* Login button styles */
$fl-login-button-border: #ededed;
$fl-login-button-hover: rgba(0, 0, 0, 0.07);
$fl-login-description: #111;

/* Login button styles */
$fl-login-button-border: #ededed;
$fl-login-button-hover: rgba(0, 0, 0, 0.07);
$fl-login-description: #111;

/* Icon colors */
$fl-icon-plus: darkgreen;
$fl-icon-minus: darkred;

/* Table styles */
$fl-table-header-cell-background: rgba(186, 191, 199, 0.5);
$fl-table-header-background: #f9fafb;
$fl-table-text: darkslategray;
$fl-table-body-border: #efefef;
$fl-table-border: #d0d5dd;
$fl-table-header-cell-text: #f47951;
$fl-table-row-background: #fec5b31f;
