@import "../../styles/fl-theme.scss";

.login-container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: grid;
  place-items: center;
}
.login-card {
  width: 70%;
  height: 70%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px,
    rgba(0, 0, 0, 0.05) 0px 5px 10px;
}
.description-box {
  width: 100%;
  height: 100%;
  padding-right: 8%;
  padding-left: 8%;
  background-color: $fl-text-orange;
  color: $fl-login-description;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: start;
}
.description-box h2 {
  color: $fl-text-white;
  font-weight: bolder;
}
.login-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}
.login-button {
  border: none;
  background-color: transparent;
  font-size: 100%;
  font-weight: 600;
  width: 100%;
  height: 40%;
  padding: 15px 40px;
  background: $fl-background-white 0% 0% no-repeat padding-box;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border: 1px solid $fl-login-button-border;
  opacity: 0.4;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
}
.login-button:hover {
  box-shadow: $fl-login-button-hover 0px 1px 2px,
    $fl-login-button-hover 0px 2px 4px, $fl-login-button-hover 0px 4px 8px,
    $fl-login-button-hover 0px 8px 16px, $fl-login-button-hover 0px 16px 32px,
    $fl-login-button-hover 0px 32px 64px;
}
.login-info {
  font-size: 80%;
  color: $fl-text-orange;
  font-weight: bold;
}
.login-text {
  padding-top: 1px;
  text-align: left;
  font: normal normal medium 29px/35px Urbanist;
  letter-spacing: 0px;
  color: $fl-text-black;
  opacity: 1;
}
.google-logo {
  opacity: 1;
  height: 22px;
  width: 22px;
  font-size: 22px;
  padding-right: 8px;
  padding-top: 3px;
}
.login-fission-logo {
  width: 60%;
  margin-top: 16%;
}
.login-form {
  margin-top: 15%;
}

/* Media queries */
@media screen and (min-width: 1440px) {
  .fission-logo {
    width: 70%;
  }
  .login-info {
    font-size: 90%;
  }
  .description-box h2 {
    font-size: 40px;
  }
  .login-info {
    font-size: 18px;
  }
  .login-button {
    padding: 30px 60px;
  }
}
@media screen and (min-width: 1024px) {
  .fission-logo {
    width: 70%;
  }
  .login-info {
    font-size: 90%;
  }
  .description-box h2 {
    font-size: 40px;
  }
  .login-info {
    font-size: 18px;
  }
  .login-button {
    padding: 30px 60px;
  }
}
@media screen and (max-width: 1022px) {
  .login-card {
    width: 90%;
  }
}

@media screen and (max-width: 765px) {
  .login-card {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    height: 90%;
  }
  .description-box {
    height: 50%;
  }
  .login-box {
    height: 50%;
  }
  .fission-logo {
    width: 60%;
    margin-top: 2%;
  }
  .login-form {
    margin-top: 6%;
  }
  .login-button {
    margin-top: 2% !important;
  }
}
@media (min-width: 570px) and (max-width: 765px) {
  .login-fission-logo {
    margin-top: 5%;
  }
}

@media screen and (max-width: 415px) {
  .login-button {
    padding: 15px 16px !important;
  }
  .fission-logo {
    width: 80%;
  }
  .description-box h2 {
    font-size: 22px;
  }
}

@media screen and (max-width: 360px) {
  .login-button {
    padding: 12px 4px !important;
  }
  .login-form {
    margin-top: 6%;
  }
  .description-box h2 {
    font-size: 18px;
  }
  .description-box p {
    font-size: 14px;
  }
  .login-card {
    height: 90%;
    width: 100%;
  }
}
