.fallback-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60vh;
  font-family: Arial, sans-serif;
}

h2 {
  margin-bottom: 1rem;
  color: #333;
}
h5 {
  color: #444;
}

pre {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  color: #666;
}

.dev-address {
  color: dodgerblue;
  text-decoration: none;
}
