@import './styles/fl-theme.scss';

// .toast-container {
//   width: 300px;
// }
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: $fl-text-white;
}

.App-link {
  color: $fl-app-link;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* ag-grid */
.ag-theme-alpine {
  width: 100%;
}
.ag-theme-alpine .ag-header-cell-resize::after {
  content: '';
  position: absolute;
  z-index: 1;
  display: block;
  left: calc(50% - 1px);
  width: 1px !important;
  height: 100% !important;
  top: auto !important;
  background-color: $fl-table-header-cell-background;
  background-color: var(--ag-header-column-resize-handle-color, $fl-table-header-cell-background);
}
.ag-header-cell-text {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 28px;
}
.ag-theme-alpine .ag-header {
  background: $fl-table-header-background;
  border-bottom: solid 0px !important;
}
.ag-theme-alpine .ag-paging-panel {
  font-size: 16px;
  color: $fl-table-text !important;
}
.ag-body-viewport {
  display: flex;
  border-top: solid 6px $fl-table-body-border;
}
.ag-theme-alpine .ag-root-wrapper {
  border: 0px solid $fl-table-border !important;
  box-shadow: 0px -2px 16px -4px rgba(16, 24, 40, 0.12);
  border-radius: 8px;
}
.ag-cell-value,
.ag-group-value {
  font-style: normal;
  font-size: 15px;
  color: $fl-table-text;
  text-align: start;
}
.ag-theme-alpine .ag-header-cell,
.ag-theme-alpine .ag-header-group-cell {
  padding-left: 12px !important;
  padding-right: 8px !important;
}
.ag-theme-alpine .ag-cell {
  padding-left: 12px !important;
  padding-right: 8px !important;
}
.ag-header-group-cell {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
.ag-header-cell-label {
  color: $fl-table-header-cell-text;
}
.ag-theme-alpine .ag-row-odd {
  background-color: $fl-table-row-background !important;
}
.ag-theme-alpine .ag-row {
  border-width: 0px !important;
}
.ag-header-row.ag-header-row-column {
  background-color: $fl-background-white;
}
.example-header {
  position: absolute;
  bottom: 5px;
}
button.dropdown-toggle.btn.btn-primary {
  background-color: $fl-background-white;
  color: #000000;
  border-color: $fl-dropdown-border;
}
.error {
  color: $fl-text-red;
}

/* Add button */
.search-bar input[type='text'] {
  line-height: 1.6;
}
.add-button {
  background-color: $fl-background-orange !important;
  border-color: $fl-border-orange !important;
}

/* container */
.body-container {
  background: $fl-body-background;
  // height: 100%;
  // width: 100%;
  overflow-y: auto;
}
.children {
  height: 93vh;
}

/* Date picker CSS */
.my-header-class .ag-header-cell-label {
  justify-content: center;
}
.session-date {
  position: relative;
  width: 100%;
}
.session-date::-webkit-calendar-picker-indicator {
  background-position: right;
  background-size: auto;
  cursor: pointer;
  position: absolute;
  bottom: 2;
  left: 0;
  right: 3px;
  top: 2;

  width: auto;
}
.plusIconStyle {
  font-size: 36px;
  color: $fl-icon-plus;
  cursor: pointer;
  margin-left: -16px;
}
.minusIconStyle {
  font-size: 36px;
  color: $fl-icon-minus;
  cursor: pointer;
}

// cancel & submit buttons
.submit-btn {
  background-color: $fl-background-orange !important;
  color: $fl-text-white !important;
}

.cancel-btn {
  background-color: $fl-background-grey !important;
  color: $fl-text-white !important;
}
