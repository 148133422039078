@import "../../styles/fl-theme.scss";

.error-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-message {
  text-align: center;
}

.error-message h1 {
  font-size: 4rem;
  font-weight: bold;
  margin-bottom: 2rem;
}

.error-message p {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.back-btn {
  background-color: $fl-background-black;
  color: $fl-text-white;
  padding: 0.75rem 1.5rem;
  font-size: 1.2rem;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.back-btn:hover {
  background-color: $fl-back-button-hover;
}
