@import '../../styles/fl-theme.scss';

.page-sidebar {
  position: fixed;
  margin-top: 7vh;
  top: 0;
  bottom: 0;
  left: 0;
  width: 7vw;
  height: 100vh;

  .sidebar {
    width: 7vw;
    height: 100%;
    background-color: $fl-background-white;
    position: relative;
    z-index: 2;
  }
  .sidebar-details {
    background-color: $fl-background-white;
    width: 12vw;
    height: 100%;
    position: absolute;
    top: inherit;
    left: -35vw;
    transition: left 1s ease;
    font-size: 1rem;
  }

  &.open {
    .sidebar-details {
      margin-left: 7vw;
      left: 0;
      box-shadow: #11111a1a 0px 0px 16px;
    }
  }
}
.sidebar-mobile {
  position: fixed;
  margin-top: 7vh;
  top: 0;
  left: -55vw;
  width: 25vw;
  height: 100%;
  z-index: 2 !important;
  background-color: $fl-background-white;
  transition: left 1s ease;
}
.sidebar-mobile-open {
  left: 0;
  box-shadow: #11111a1a 0px 0px 16px;
}

.sidebar-styles {
  font-weight: 500;
  font-size: 18px;
  text-decoration: none;
  color: $fl-text-dark;
  align-items: start;
}
.mobile-view-sidebar-details {
  width: 100%;
  display: grid;
  grid-template-columns: 7vw 38vw;
  align-items: center;
  justify-content: space-between;
}

.sidebar-styles.active {
  background-color: $fl-background-brown;
}
.active-sidebar-tab {
  background-color: $fl-background-brown;
}

.sidebar-styles:hover {
  color: $fl-text-dark;
}

.sidebar-items {
  margin-left: 26%;
}

a.sidebar-styles.active span {
  background: $fl-background-orange;
}

a.sidebar-styles:hover {
  // background-color: $fl-background-brown;
}

@media screen and (max-width: 670px) {
  .sidebar-mobile {
    width: 28vw;
  }
  .mobile-view-sidebar-details {
    grid-template-columns: 9vw calc(100% - 9vw);
  }
}
@media screen and (max-width: 550px) {
  .sidebar-mobile {
    width: 36vw !important;
  }
  .mobile-view-sidebar-details {
    grid-template-columns: 12vw calc(100% - 12vw);
  }
}

@media screen and (max-width: 420px) {
  .sidebar-mobile {
    width: 45vw !important;
  }
  .mobile-view-sidebar-details {
    grid-template-columns: 14vw calc(100% - 14vw);
  }
  .sidebar-styles {
    font-size: 16px !important;
  }
}

@media screen and (max-width: 550px) {
  .sidebar-mobile {
    width: 36vw !important;
  }
  .mobile-view-sidebar-details {
    grid-template-columns: 12vw calc(100% - 12vw);
  }
}

@media screen and (max-width: 420px) {
  .sidebar-mobile {
    width: 45vw !important;
  }
  .mobile-view-sidebar-details {
    grid-template-columns: 14vw calc(100% - 14vw);
  }
  .sidebar-styles {
    font-size: 16px !important;
  }
}
