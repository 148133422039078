@import '../../styles/fl-theme.scss';

.edit-icon,
.delete-icon {
  color: $fl-text-gray;
}
.edit-icon:hover {
  color: $fl-text-orange;
  cursor: pointer;
}
.delete-icon:hover {
  color: $fl-text-brown;
  cursor: pointer;
}
.billable-true {
  color: $fl-text-green;
}
.billable-false {
  color: $fl-text-red;
}
.rbt-token-label {
  color: #ffffff;
  background-color: #f47951;
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
  padding: 3px 6px;
}
button.close.btn-close.rbt-close.rbt-token-remove-button {
  color: #ffffff;
  background-color: #f47951;
}
.rbt-input-multi .rbt-input-wrapper {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -4px;
  margin-top: -1px;
  overflow: hidden;
}
.rbt-input-multi.form-control {
  height: 2.3rem;
  margin: auto;
  overflow-x: hidden;
  overflow-y: auto;
  scroll-snap-type: y mandatory;
}
.rbt-token {
  background-color: #e7f4ff;
  border: 0;
  border-radius: 0.25rem;
  color: #007bff;
  display: inline-flex;
  line-height: 1rem;
  margin: 0px 5px 2px 0;
}
.rbt-token .rbt-token-remove-button {
  background-image: none;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  box-shadow: none;
  color: inherit;
  display: flex;
  justify-content: center;
  font-size: inherit;
  font-weight: normal;
  opacity: 1;
  outline: none;
  padding: 0.25rem 0.5rem;
  padding-left: 0;
  text-shadow: none;
}
// .content-box {
//   margin-right: 2rem;
// }
.link {
  text-decoration: underline;
  color: rgb(38, 114, 226);
  cursor: pointer;
  background-color: transparent;
  border: none;
}
.note {
  color: $fl-text-gray;
}

@media screen and (max-width: 799px) {
  .content-box {
    margin-right: 0rem !important;
  }
}
