@import '../../styles/fl-theme.scss';

.navbar {
  height: 7vh;
  padding: 0 !important;
  background-color: $fl-background-white;
}
.header-component {
  z-index: 3;
}
.account-details {
  display: none !important;
  height: 100%;
  background-color: $fl-background-white;
  z-index: 6;
  transition: right 1s ease;
}
.collapse {
  margin-right: 3rem;
}

.fission-logo {
  width: 15rem;
  height: 2rem;
}

.userIcon {
  width: 1.9rem;
}

#basic-nav-dropdown {
  color: $fl-text-dark;
}

.menu-container {
  width: 7vw;
  display: grid;
  place-items: center;
  font-size: 1.1rem;
  height: 100%;
  cursor: pointer;
}
.dropdown-menu.show {
  min-width: 7.5rem !important;
}
.layout-children {
  margin-left: 7vw;
  z-index: 1;
  padding: 16px;
}
.shirnk-layout {
  margin-left: 19vw !important;
  transition: margin 1s ease;
}

/* Media Queries */
@media screen and (max-width: 800px) {
  .account-details-desktop {
    display: none !important;
  }
  .logo-box {
    margin-right: 0 !important;
  }
  .account-details {
    display: flex !important;
    justify-content: flex-end !important;
  }
  .collapse {
    margin-right: 0 !important;
  }
  .dropdown-menu[data-bs-popper] {
    left: unset !important;
    right: 0 !important;
    padding: 0 !important;
    font-size: 0.8rem !important;
    width: auto !important;
    overflow: hidden;
    height: auto;
  }
  .navbar {
    padding: 0 !important;
  }
  .fission-logo {
    width: 13rem !important;
  }
  .userIcon {
    width: 1.4rem !important;
  }
  .layout-children {
    margin-left: 0 !important;
  }
}
@media screen and (max-width: 670px) {
  .menu-container {
    width: 9vw !important;
  }
}
@media screen and (max-width: 550px) {
  .menu-container {
    width: 12vw !important;
  }
}

@media screen and (max-width: 420px) {
  .menu-container {
    width: 14vw !important;
  }
  .fission-logo {
    width: 11rem !important;
  }
}
